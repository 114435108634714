/*
 * Copyright 2017-2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import * as React from 'react';
import { Component } from 'react';
import { Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import {
  Container,
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  Form
} from 'reactstrap';
import logo from './assets/img/brand/logo.png'

const Constants = {
    AUTH_SOURCE_KEY: 'amplify-react-auth-source',
    SIGNING_IN_WITH_HOSTEDUI_KEY: 'amplify-signin-with-hostedUI'
};

const AUTHENTICATOR_AUTHSTATE = 'amplify-authenticator-authState';


class  Loading extends Component {


  componentDidMount() {
    return Auth.currentAuthenticatedUser()
            .then(user => {
            })
            .catch(err => {


              this.id = setTimeout(() => {
                Auth.federatedSignIn({provider:"Okta"});
              }, 500);
            });

  }


  componentWillUnmount() {
    if(this.id != null) {
      clearTimeout(this.id)
    }
  }

  render() {
    return (
      <div className="app flex-row align-items-center animated fadeIn">
        <Container>
          <Row className="justify-content-center">
            <Col md="4">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <p><img src={logo} alt={"logo"}/> </p>
                      <h4>Cloud Admin Dashboard</h4>
                      <div className="flex-row align-items-center">
                        <i className="fa fa-spinner fa-spin" /> Logging in...
                      </div>
                    </Form>
                  </CardBody>
                </Card>

              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

}


export const EmptyContainer = ({ children }) => <>{children}</>;

export default class Authenticator extends Component {
    constructor(props) {
        super(props);

        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleAuthEvent = this.handleAuthEvent.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);

        this._initialAuthState = this.props.authState || 'signIn';
        this.state = { authState: 'loading' };
        Hub.listen('auth', this.onHubCapsule);
    }

    componentDidMount() {
        const config = this.props.amplifyConfig;
        if (config) {
            Auth.configure(config);
        }
        this._isMounted = true;
        // the workaround for Cognito Hosted UI
        // don't check the user immediately if redirected back from Hosted UI
        // instead waiting for the hub event sent from Auth module
        // the item in the localStorage is a mark to indicate whether
        // the app is redirected back from Hosted UI or not
        const byHostedUI = localStorage.getItem(Constants.SIGNING_IN_WITH_HOSTEDUI_KEY);
        localStorage.removeItem(Constants.SIGNING_IN_WITH_HOSTEDUI_KEY);
        if (byHostedUI !== 'true') this.checkUser();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    checkUser() {

        if (!Auth || typeof Auth.currentAuthenticatedUser !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        return Auth.currentAuthenticatedUser()
            .then(user => {
                if (!this._isMounted) { return; }
                this.handleStateChange('signedIn', user);
            })
            .catch(err => {

                if (!this._isMounted) { return; }
                let cachedAuthState = null;
                try {
                    cachedAuthState = localStorage.getItem(AUTHENTICATOR_AUTHSTATE);
                } catch (e) {
                    console.log('Failed to get the auth state from local storage', e);
                }
                const promise = cachedAuthState === 'signedIn'? Auth.signOut() : Promise.resolve();
                promise.then(() => this.handleStateChange(this._initialAuthState))
                    .catch((e) => {
                        console.log('Failed to sign out', e);
                    });
            });
    }

    onHubCapsule(capsule) {
        const { channel, payload } = capsule;

        if (channel === 'auth') {
            console.log(payload)
            switch (payload.event) {
                case 'cognitoHostedUI':
                    this.handleStateChange('signedIn', payload.data);
                    break;
                case 'cognitoHostedUI_failure':
                    this.handleStateChange('signIn', null);
                    break;
                case 'parsingUrl_failure':
                    this.handleStateChange('signIn', null);
                    break;
                case 'signOut':
                    this.handleStateChange('signIn', null);
                    break;
                case 'customGreetingSignOut':
                    this.handleStateChange('signIn', null);
                    break;
                case 'parsingCallbackUrl':
                    localStorage.setItem(Constants.SIGNING_IN_WITH_HOSTEDUI_KEY, 'true');
                    break;
                default:
                    break;
            }
        }
    }

    handleStateChange(state, data) {
      console.log('authenticator state change ' + state, data);
      if (state === this.state.authState) { return; }

      if (state === 'signedOut') { state = 'signIn';

      }

      try {
          localStorage.setItem(AUTHENTICATOR_AUTHSTATE, state);
      } catch (e) {
          console.log('Failed to set the auth state into local storage', e);
      }



      if (this._isMounted) {
          this.setState({ authState: state, authData: data, error: null, showToast: false });
      }
      if (this.props.onStateChange) { this.props.onStateChange(state, data); }
    }

    handleAuthEvent(state, event, showToast = true) {
      if (event.type === 'error') {
          const map = this.props.errorMessage;
          const message = (typeof map === 'string')? map : map(event.data);
          this.setState({ error: message, showToast });
      }
    }

    render() {
      const { authState } = this.state;
      // If container prop is undefined, default to AWS Amplify UI Container
      // otherwise if truthy, use the supplied render prop
      // otherwise if falsey, use EmptyContainer


      let props_children = [];
      if (typeof this.props.children === 'object') {
          if (Array.isArray(this.props.children)){
              props_children = this.props.children;
          } else {
              props_children.push(this.props.children);
          }
      }


      return (
          <>
              { authState === "signedIn" ? props_children : <Loading/> }
          </>
      );
    }
}
