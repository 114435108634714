import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import awsconfig from './config';
import './App.scss';
import Auth from '@aws-amplify/auth';
import Authenticator from './Authenticator.jsx';
import API from '@aws-amplify/api';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;


// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));


Auth.configure({
  Auth: awsconfig.Auth,
});

API.configure({
  API: {
    endpoints: [
      {
        name: "ConversionAPI",
        endpoint: "https://imlmjncis5.execute-api.us-west-2.amazonaws.com/Prod",
        custom_header: async () => {
          return { Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }

        }
      }
    ]
  }


});


class App extends Component {

  render() {
    return (

    <Authenticator>
      <HashRouter>
        <React.Suspense fallback={loading()}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
          <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
          <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
        </Switch>
        </React.Suspense>
      </HashRouter>
    </Authenticator>
    );
  }
}

export default App;