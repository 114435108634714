const config = {
    // OPTIONAL - if your API requires authentication
    Auth: {
        region: 'us-west-2',
        userPoolId: 'us-west-2_hXF917ECh',
        userPoolWebClientId: '7f7rhfodeiggfvujia3rjp4cvh',
        oauth: {
            domain: 'conversion-service-test-userpool.auth.us-west-2.amazoncognito.com',
            scope: ['conversion/request'],
            redirectSignIn: [window.location.protocol, "//", window.location.hostname, window.location.port == "" ? "": ":" +window.location.port].join(''),
            redirectSignOut: [window.location.protocol, "//", window.location.hostname, window.location.port == "" ? "": ":" +window.location.port].join(''),
            responseType: 'code'
        }
    }

}

export default config;